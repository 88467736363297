import { useParams } from "react-router-dom";

import { useDocumentTitle } from "../hooks";
import {
  Container,
  SubNavContentContainer,
  NavContent,
  MainContent,
} from "../components/common";

import SEO from "../components/SEO/SEO";

export const LegalPage = () => {
  useDocumentTitle("Legal");
  const { page } = useParams();

  return (
<div>
  <SEO
    title="Pittbos - Contractor Estimating Platform - Legal"
    description="Pittbos - Contractor Estimating Platform - Legal"
    name="Pittbos"
    type="website"
  />


    <Container>
     
      <SubNavContentContainer
        nav={<NavContent page={page} />}
        content={<MainContent page={page} />}

      />
    </Container>
</div>

  )
};