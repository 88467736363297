import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { Modal } from "../common/Modals";
import { GetADemoForm } from "./GetADemoForm";

export const GetADemoModal = ({ setIsVisible }) => {
  const [isSend, setIsSend] = useState(false);

  return (
    <React.Fragment>
      <Modal title={!isSend && "GET A DEMO"} setIsVisible={setIsVisible}>
        <MailchimpSubscribe
          url={process.env.REACT_APP_MAILCHIMP_URL}
          render={(props) => <GetADemoForm {...props} setIsSend={setIsSend} />}
        />
      </Modal>
    </React.Fragment>
  );
};
