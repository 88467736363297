import clsx from 'classnames';

import { Container } from '../common';

import styles from './AboutPittbosPlatform.module.css';


const titleStyles = clsx(styles.title, "title text-white");


export function AboutPittbosPlatform() {
  return (
   
    <Container backgroundClasses={styles.containerBackground} wrapperClasses={styles.wrapper}>
      <p className={titleStyles}>
        <span className="text-red">Pittbos</span>
        {' '}
        is an all-in-one,
        {' '}
        <span className="text-red">user friendly</span>,
        accurate sales management
        {' '}
        <span className="text-red">software tool</span>
      </p>
      <div className={styles.text}>
        <p className={styles.textBold}>
          Built by and for asphalt contractors and suppliers.
        </p>
        <p className={styles.textBold}>
          We're not a technology company trying to capitalize on the asphalt industry. We're asphalt experts who
          couldn't find the technology solution we were looking for, so we built it.
        </p>
        <p>
          Every element of our product has been specifically designed to streamline your company's ability to capture
          new leads, evaluate your pricing, provide accurate quotes, manage customer relationships, process invoices,
          and more.
        </p>
        <p>
          Pittbos is the expert support your asphalt company has been waiting for.
        </p>
      </div>
    </Container>

  )
}