import React, { useEffect } from "react";

import {
  HeroBlock,
  CustomerFollowUp,
  Posts,
  AboutPittbosContractor,
  BacklogAndInvoicing,
  WhyPittbosExists,
} from "../components";

import SEO from "../components/SEO/SEO";

import { useDocumentTitle } from "../hooks";

import { scrollToComponent } from "../utils";

export const MainPage = () => {
  useDocumentTitle("Pittbos - Contractor Estimating Platform - Home");

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        scrollToComponent(
          window.document.getElementById(window.location.hash.slice(1))
        ),
      0
    );

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (



    <React.Fragment>
      <div>        
        <SEO
          title='Pittbos - Contractor Estimating Platform - Home'
         description='Pittbos - Contractor Estimating Platform - Home'
         name= 'Pittbos'
        type= 'website'
        />
       <HeroBlock />
      <AboutPittbosContractor/>
     
      <CustomerFollowUp/>
      <BacklogAndInvoicing/>
      <WhyPittbosExists/> 
      <Posts/>
      </div>
    </React.Fragment>

  );
};
