export const legalData = [
    {
      title: "1. Definitions",
      nav: "Definitions",
      id: "1",
      description: [
        {
          label: "",
          paragraphs: [
            "As used in this Agreement, the terms set forth in this Section will have the meanings provided herein. Other" +
            " terms used in this Agreement but not defined in this Section shall have the meanings ascribed thereto or are" +
            " otherwise defined in the context in which they are used and will have the meanings therein indicated.",
            "“Affiliate” means, with respect to any entity, any other entity directly or indirectly controlling or " +
            "controlled by, or under common control with, such entity.  For purposes of this Agreement, “control”" +
            " (including the terms “controlled by” and “under common control with”) shall mean the power, directly " +
            "or indirectly, to direct or cause the direction of the management or policies of such entity, whether " +
            "through the ownership of voting securities, by contract or otherwise.",
            "CLIENT Data” means any data that is entered, uploaded to the Application or otherwise supplied to Pittbos" +
            " LLC by CLIENT.  With the exception of any applicable third party rights, CLIENT shall exclusively own " +
            "all right, title and interest in CLIENT Data, including all copyrights and any other Intellectual Property" +
            " Rights.  Nothing in this Agreement shall be construed as conveying any rights or interest in the CLIENT" +
            " Data to Pittbos LLC.",
            "“Intellectual Property Rights” means any and all rights that may exist from time to time in a specified" +
            " jurisdiction under patent law, copyright law, publicity rights law, moral rights law, trade secret law," +
            " trademark law, unfair competition law or other similar protections, whether or not such rights are registered" +
            " or perfected."
          ]
        }
      ]
    },
    {
      title: "2. Application License",
      nav: "Application License",
      id: "2",
      description: [
        {
          label: "",
          paragraphs: [
            "Pittbos LLC hereby grants CLIENT a non-transferable, non-exclusive, license to access and use the Application during the term of this Agreement via a web browser over the Internet for the number of users as specified in Section 13 of this Agreement.  The license fee for the rights granted in this Section  (“Application License Fee”) during the Initial Term shall be as set forth in Section13.CLIENT Responsibilities."
          ]
        }
      ]
    },
    {
      title: "3. Client Responsibilities",
      nav: "Client Responsibilities",
      id: "3",
      description: [
        {
          label: "",
          paragraphs: [
            "Unless otherwise agreed, CLIENT will be responsible for creating and modifying CLIENT Data and keeping" +
            " CLIENT Data input into the Application current and accurate.  Pittbos LLC will have no responsibility " +
            "for assisting CLIENT in creating, modifying, or inputting the CLIENT Data, unless otherwise agreed " +
            "to by the parties in writing."
          ]
        },
        {
          label: "Authorized Use",
          paragraphs: [
            "If Pittbos LLC provides CLIENT with a password to access the Application, then CLIENT is responsible" +
            " for protecting the password and for any authorized or unauthorized use made of the password that is" +
            " not the result of an act or omission of Pittbos LLC.  CLIENT will cooperate fully with law enforcement" +
            " authorities in the detection and prosecution of illegal activity related to unauthorized use of the Application.  \nBPS Representations and Warranties",
            "CLIENT represents and warrants to Pittbos LLC as follows: (a) has all power necessary to execute," +
            " deliver and perform its obligations under this Agreement and (b) the execution, delivery and performance" +
            " of this Agreement by CLIENT has been approved by any necessary governing body to the extent required;" +
            " and is not contrary to, or in conflict with, the articles of incorporation or bylaws of CLIENT, or any" +
            " material agreement by which CLIENT is bound or any applicable law."
          ]
        }
      ]
    },
  {
    title: "4. Pittbos LLC Responsibilities",
    nav: "Pittbos LLC Responsibilities",
    id: "4",
    description: [
      {
        label: "Security",
        paragraphs: [
          "Pittbos LLC shall provide a secure environment located in the contiguous United States for the CLIENT Data" +
          " and any hardware and software, including servers, network and data components, to be provided by Pittbos" +
          " LLC as part of its performance under this Agreement in accordance with accepted practices for the intended" +
          " commercial use in order to prevent unauthorized access, use or modification of, and otherwise protect the" +
          " Application and the CLIENT Data.  Access to facilities housing the Application and the CLIENT Data shall be" +
          " restricted to only allow access to personnel who have a need to know in connection with operation and support" +
          " of the Application."
        ]
      },
      {
        label: "Services",
        paragraphs: [
          "Pittbos LLC shall make available such resources as are reasonably required to: (a) train employee(s)" +
          " designated by CLIENT; (b) support the obligations of CLIENT provided in Section 3; and (c) otherwise" +
          " support the Application as provided under this Agreement.  Pittbos LLC shall respond to problems or issues" +
          " reported by an authorized CLIENT representative in as timely a manner as possible during the course of our" +
          " normal business and support hours (Monday-Friday, 8:00 AM – 8:00 PM Eastern Time, excluding nationally recognized" +
          " holidays)."
        ]
      },
      {
        label: "Pittbos Representations and Warranties",
        paragraphs: [
          "Pittbos LLC represents and warrants to CLIENT as follows: (a) the execution, delivery and performance" +
          " of this Agreement by Pittbos LLC has been approved by any necessary company action and is not contrary" +
          " to, or in conflict with, the formation and governance documents of Pittbos LLC, any material agreement" +
          " by which Pittbos LLC is bound or any applicable law; and (b) the Application and Services delivered or" +
          " to be delivered pursuant to this Agreement are not contrary to, or in conflict with, nor do they infringe" +
          " upon, any patent, trademark, copyright or other intellectual property right of any person or entity and that" +
          " there are no such claims of infringement as of the date hereof."
        ]
      }
    ]
  },
  {
    title: "5. Restrictions",
    nav: "Restrictions",
    id: "5",
    description: [
      {
        label: "General Use Restrictions",
        paragraphs: [
          "CLIENT shall not directly or indirectly copy or reproduce all or any part of the Application, whether" +
          " electronically, mechanically or otherwise, in any form including, but not limited to, the copying of" +
          " presentation, style or organization, without prior written permission from Pittbos LLC; provided, however," +
          " CLIENT may reproduce and distribute any Application output generated from the CLIENT Data."
        ]
      },
      {
        label: "Specific Use Restrictions",
        paragraphs: [
          "CLIENT will take reasonable care not to, and shall not intentionally or knowingly, use the Application to post," +
          " transmit, distribute, store or destroy any information: (a) in violation of any applicable law, statute," +
          " ordinance or regulation; (b) in a manner that will infringe the Intellectual Property Rights of others; (c)" +
          " that is defamatory or trade libelous, or (d) that contains any viruses, Trojan horses, worms, time bombs, cancel" +
          " bots or other computer programming routines that are intended to damage, detrimentally interfere with" +
          ", surreptitiously intercept or expropriate any system, data or personal information."
        ]
      },
      {
        label: "Security",
        paragraphs: [
          "CLIENT shall make reasonable effort not to violate or attempt to violate the security of the Application."
        ]
      },
      {
        label: "Reverse Engineering",
        paragraphs: [
          "Except as provided or allowed by law, CLIENT shall not attempt to decipher, decompile, disassemble or" +
          " reverse engineer any of the software comprising or in any way making up a part of the Application."
        ]
      },
    ]
  },
  {
    title: "6. Payment",
    nav: "Payment",
    id: "6",
    description: [
      {
        label: "Prices and Rates",
        paragraphs: [
          "During the term of this Agreement and subject to appropriation, CLIENT will pay Pittbos LLC the fees" +
          " (the “Fees”) set forth in Section 13. In the case of any additional services, the services of Pittbos" +
          " LLC personnel will be billed to CLIENT on a per hour basis in accordance with the rates mutually agreed " +
          "to between CLIENT and Pittbos LLC for such service prior to the start of such service."
        ]
      },
      {
        label: "Invoices",
        paragraphs: [
          "Pittbos LLC shall invoice CLIENT for the Fees and for all out-of-pocket expenses incurred by Pittbos" +
          " LLC during the relevant period; provided, however, that CLIENT shall only be liable to pay for Pittbos" +
          " LLC reasonable and actual travel-related expenses, including all travel, meals, lodging and incidental" +
          " expenses, provided such expenses have been authorized by CLIENT in advance.",
          "CLIENT shall pay Pittbos LLC within thirty (30) days of receipt of a correct invoice, all" +
          " undisputed charges and promptly notify Pittbos LLC in writing of any disputed amount.  Pittbos " +
          "LLC shall supply CLIENT with documentation to support the validity of any disputed charge."
        ]
      }
    ]
  },
  {
    title: "7. Proprietary Rights",
    nav: "Proprietary Rights",
    id: "7",
    description: [
      {
        label: "Ownership",
        paragraphs: [
          "Pittbos LLC represents and warrants that it has the right to use all of the inventions, software," +
          " technology, expertise, know-how, materials and Intellectual Property Rights contained in or a part" +
          " of the Application. Pittbos LLC Intellectual Property Rights shall in any event include the Application" +
          " and any works based on or derived from the foregoing including any future versions, releases, upgrades" +
          " or enhancements of the Application."
        ]
      },
      {
        label: "Trademarks and Copyrights",
        paragraphs: [
          "CLIENT shall not permit any of its employees to remove, alter, deface, obscure or otherwise modify the" +
          " trademarks, logos, or other proprietary marks (“Marks”) displayed on the Application, whether such Marks" +
          " are displayed or otherwise rendered by software or on printed media.  CLIENT shall also not knowingly adopt" +
          " or otherwise utilize any trademarks or logos containing confusingly similar names, designs, or other indicia" +
          " to the Marks nor dilute the Marks in any manner.  Pittbos LLC shall not adopt or otherwise use any trademarks" +
          ", logos or proprietary marks of CLIENT (“CLIENT Marks”), and shall not adopt or otherwise use any trademarks," +
          " logos or proprietary marks containing confusingly similar names, designs, or other indicia " +
          "to the CLIENT Marks nor dilute the CLIENT Marks in any manner."
        ]
      },
    ]
  },
  {
    title: "8. Confidential Information",
    nav: "Confidential Information",
    id: "8",
    description: [
      {
        label: "Treatment of Confidential Information",
        paragraphs: [
          "Both CLIENT and Pittbos LLC agree to (a) hold in strict confidence all confidential and proprietary information," +
          " including each party’s Intellectual Property Rights and the CLIENT Data, whether in written, oral or other form," +
          " which it received from the other party prior to, or in the course of, this Agreement (the “Confidential Information”)," +
          " (b) use the Confidential Information solely to perform or to exercise its rights under this Agreement," +
          " and (c) not to transfer, display, convey or otherwise disclose or make available all or any part of such" +
          " Confidential Information to any third party except to those advisors, affiliates, agents, assigns, attorneys," +
          " employees, directors, officers and/or members (“Agents”) with a need-to-know the Confidential Information for" +
          " the purposes of the Agreement.  The receiving party shall be responsible for any breach of this Agreement by the" +
          " receiving party or its Agents.  The receiving party shall use the same degree of care to protect the Confidential" +
          " Information as the receiving party employs to protect its own information of like importance, but in no event" +
          " less than a reasonable degree of care based on industry standard.  The term “Confidential Information” shall" +
          " also include the existence, terms and conditions of this Agreement."
        ]
      },
      {
        label: "Exclusions",
        paragraphs: [
          "The term “Confidential Information” shall not include information that is:",
          "a. in the public domain through no fault of receiving party or of any other person or entity" +
          " that is similarly contractually or otherwise obligated;",
          "b. obtained independently from a third party without an obligation of confidentiality to the" +
          " disclosing party and without breach of this Agreement.",
          "c. required to be disclosed by law, a court, or government entity (provided that, in the event that" +
          " disclosure is so required, prior to disclosure, such party shall provide the other 15 days prior written" +
          " notice of the obligation to disclose; if not legally possible to provide such notice, then reasonable" +
          " notice of obligation shall be provided).",
          "CLIENT agrees that Pittbos LLC may use CLIENT’S name and logo on Pittbos LLC website, and as a part of a general" +
          " list of Pittbos LLC customer for use and reference in corporate, promotional, and marketing literature.",
        ]
      },
      {
        label: "Equitable Remedies",
        paragraphs: [
          "Each party agrees that the other party shall have no adequate remedy at law if there is a breach or threatened breach of this Section 8 and, accordingly, that either party shall be entitled (in addition to any legal or equitable remedies available to such party) to seek injunctive or other equitable relief to prevent or remedy such breach.",
          "In the event that receiving party is requested or required by legal or regulatory authority to disclose any Confidential Information, the receiving party shall promptly notify the disclosing party of such request or requirement prior to disclosure, if permitted by law, so that disclosing party may seek an appropriate protective order.  In the event that a protective order or other remedy is not obtained, receiving party agrees to furnish only that portion of the Confidential Information that it reasonably determines, in consultation with its counsel, is consistent with the scope of the subpoena or demand, and to exercise reasonable efforts to obtain assurance that confidential treatment will be accorded such Confidential Information.  Receiving party will provide reasonable cooperation to disclosing party and its legal counsel with respect to performance of the covenants undertaken pursuant to this Section 8.4."
        ]
      },
    ]
  },
  {
    title: "9. Disclaimer of Warranties",
    nav: "Disclaimer of Warranties",
    id: "9",
    description: [
      {
        label: "",
        paragraphs: [
          "EXCEPT AS EXPRESSLY SET FORTH IN SECTIONS 3 AND 4, NEITHER PARTY MAKES ANY EXPRESS OR IMPLIED WARRANTIES," +
          " CONDITIONS OR REPRESENTATIONS TO THE OTHER PARTY WITH RESPECT TO THE APPLICATION PROVIDED HEREUNDER OR" +
          " OTHERWISE REGARDING THIS AGREEMENT, WHETHER ORAL OR WRITTEN, EXPRESS, IMPLIED OR STATUTORY.  WITHOUT LIMITING" +
          " THE FOREGOING, ANY IMPLIED WARRANTY OR CONDITION OF MERCLIENTNTABILITY, AND THE IMPLIED WARRANTY OR CONDITION" +
          " OF FITNESS FOR A PARTICULAR PURPOSE ARE EXPRESSLY EXCLUDED AND DISCLAIMED."
        ]
      },
    ]
  },
  {
    title: "10. Indemnity; Limitation of Liability",
    nav: "Indemnity",
    id: "10",
    description: [
      {
        label: "",
        paragraphs: [
          "CLIENT shall indemnify, defend and hold harmless Pittbos LLC, as well as Pittbos LLC subsidiaries," +
          " licensees and Affiliates, and each of their officers, shareholders, directors, employees and agents" +
          " (collectively, the “Pittbos LLC Indemnified Parties”) from and against any and all Losses incurred by," +
          " borne by or asserted against any of the Pittbos LLC Indemnified Parties in any way relating to, arising out" +
          " of or resulting from: (i) CLIENT’s use of the Application or Services in breach of this Agreement; or (ii)" +
          " breach of any representation or warranty of CLIENT contained herein.",
          "Pittbos LLC shall indemnify, defend and hold harmless CLIENT, its Affiliates and licensees, and each of their " +
          "officers, shareholders, directors, employees and agents (collectively, the “CLIENT Indemnified Parties”) from and" +
          " against any and all liabilities, obligations, losses, damages, claims, demands, suits, actions, deficiencies," +
          " penalties, taxes, levies, fines, judgments, settlements, costs, expenses, legal fees and disbursements, and" +
          " accountants’ fees disbursements (collectively, “Losses”) incurred by, borne by or asserted against any of the" +
          " CLIENT Indemnified Parties in any way relating to, arising out of or resulting from: (i) Pittbos LLC performance," +
          " failure to perform or improper performance under this Agreement and any negligence, gross negligence or willful" +
          " misconduct of any employee or subcontractor of Pittbos LLC; (ii) breach of any representation, warranty or " +
          "covenant of Pittbos LLC contained herein; or (iii) any actual or alleged infringement of any Intellectual Property" +
          " Right by the Application or Services."
        ]
      },
      {
        label: "Indemnification Procedures",
        paragraphs: [
          "Promptly after any party entitled to indemnification under this Section 10 (individually, an “Indemnitee”)" +
          " obtains knowledge of the potential existence or commencement of any third-party claim, action, suit or proceeding" +
          " (a “Claim”), in respect of which Indemnitee is or may be entitled to indemnification under this Agreement," +
          " such Indemnitee shall promptly notify the other party (the “Indemnitor”) of such Claim in writing setting forth" +
          " in reasonable detail the specific facts and circumstances relating to such Claim and the amount of Losses subject" +
          " to the Claim (or an estimate thereof if the actual amount is not known or not capable of reasonable calculation);" +
          " provided, however, that any failure to give such notice will not waive any rights of Indemnitee except to the" +
          " extent that the rights of Indemnitor are actually prejudiced thereby.  Indemnitor shall assume the defense and" +
          " settlement of such Claim with counsel reasonably satisfactory to Indemnitee at Indemnitor’s sole risk and expense;" +
          " provided, however, that Indemnitee (i) shall be permitted to join in the defense and settlement of such Claim" +
          " and to employ counsel at its own expense; (ii) shall reasonably cooperate with Indemnitor in the defense and any" +
          " settlement of such Claim, at Indemnitor’s expense, in any manner reasonably requested by Indemnitor; (iii) shall" +
          " have the right to pay or settle such Claim at any time in which event Indemnitee shall be deemed to have waived" +
          " any right to indemnification therefore by Indemnitor; and (iv) shall have the right to consent in writing to any" +
          " settlement provided that Indemnitee shall be deemed to have consented to any settlement that (A) includes a" +
          " release of all covered claims pending against Indemnitee; (B) contains no admission of liability or wrongdoing" +
          " by Indemnitee; and (C) imposes no obligations upon Indemnitee other than an obligation to stop using any" +
          " infringing items.",
          "If Indemnitor fails to assume the defense of such Claim or, having assumed the defense and settlement" +
          " of such Claim, fails reasonably to contest such Claim in good faith, Indemnitee, without waiving its right" +
          " to indemnification, may assume the defense and settlement of such Claim; provided, however, that (i) Indemnitor" +
          " shall be permitted to join in the defense and settlement of such Claim and to employ counsel at its own expense;" +
          " (ii) Indemnitor shall cooperate with Indemnitee in the defense and settlement of such Claim in any manner" +
          " reasonably requested by Indemnitee; and (iii) Indemnitee shall not settle such Claim without the written consent" +
          " of Indemnitor, provided that Indemnitor shall be deemed to have consented to any settlement that (A) includes " +
          "a release of all covered Claims pending against Indemnitor; (B) contains no admission of liability or wrongdoing" +
          " by Indemnitor; and (C) imposes no obligations upon Indemnitor other than an obligation to stop using any" +
          " infringing items.  Indemnitor shall be liable to Indemnitee for all costs and expenses incurred in connection" +
          " with the defense and settlement of any Claim pursuant to this Section.",
          "Upon a determination of liability in respect of this Section 10, Indemnitor shall pay Indemnitee the amount" +
          " so determined within 10 business days after the date of such determination (such tenth business day, the" +
          " “Due Date”).  If there should be a dispute as to the amount or manner of determination of any indemnity" +
          " obligation owed under this Agreement, Indemnitor shall nevertheless pay when due such portion, if any, of the" +
          " obligation as shall not be subject to dispute.  Upon the payment in full of any claim, either by setoff or" +
          " otherwise, the party or entity making payment shall be subrogated to the rights of Indemnitee against any person," +
          " firm, corporation or other entity with respect to the subject matter of such claim."
        ]
      },
      {
        label: "Intellectual Property Remedies",
        paragraphs: [
          "In the event of a claim under Section 10 (iii) above, and in addition to all other obligations of Pittbos" +
          " LLC in this Section 10, Pittbos LLC shall at its sole expense:  (a) procure for CLIENT the right to continue" +
          " use of such infringing Licensed Application or Services, or any component thereof; or (b) replace or modify" +
          " the same with non-infringing products or services satisfactory to CLIENT, provided that Pittbos LLC shall" +
          " provide CLIENT with a comparable temporary replacement product or reimburse CLIENT for all costs incurred by" +
          " CLIENT in obtaining an alternative product in the event CLIENT cannot use the affected Product.  If Pittbos" +
          " LLC cannot accomplish any of the foregoing within a reasonable time and at commercially reasonable rates, then" +
          " Pittbos LLC shall accept the return of the infringing component of the licensed Application or Services, along" +
          " with any other components of any products rendered unusable by CLIENT as a result of the infringing component," +
          " and refund the price paid to Pittbos LLC for such components."
        ]
      },
      {
        label: "Limitation of Liability",
        paragraphs: [
          "EXCEPT FOR INDEMNITY OBLIGATIONS UNDER THIS AGREEMENT RELATED TO THIRD-PARTY CLAIMS," +
          " IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY (OR THE INDEMNIFIED PARTIES OF SUCH PARTY)" +
          " FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING (WITHOUT LIMITATION)" +
          " LOSS OF PROFIT, INCOME OR SAVINGS, EVEN IF ADVISED OF THE POSSIBILITY THEREOF"
        ]
      },
    ]
  },
  {
    title: "11. Term and Termination",
    nav: "Term and Termination",
    id: "11",
    description: [
      {
        label: "Term",
        paragraphs: [
          "The term of this Agreement shall commence on the Effective Date and shall remain in effect for one years," +
          " unless terminated as provided in this Section 11 (“Term”). This Agreement shall automatically renew for" +
          " additional one-year terms unless either party provides the other party with a written notice of termination" +
          " at least 60 days prior to the expiration of the then current term."
        ]
      },
      {
        label: "Termination for Cause",
        paragraphs: [
          "Either party may terminate this Agreement for cause in the event that the other party" +
          " fails to cure a material breach of this Agreement within 30 days after receiving written notice thereof"
        ]
      },
      {
        label: "Effect of Termination",
        paragraphs: [
          "In the event this Agreement is terminated for cause pursuant to this Section 11," +
          " the non-terminating party may pursue any and all remedies available to it under this Agreement," +
          " at law, or in equity.  The remedies stated herein are cumulative and are in addition to any remedies" +
          " available at law or equity.  In the event of termination of this Agreement for any reason, Pittbos LLC" +
          " shall provide to CLIENT all CLIENT Data in its possession, in its then-existing state and in ten days from" +
          " the date of termination.  Pittbos LLC will reasonably cooperate with CLIENT for an orderly transition of the" +
          " services and/or products contemplated by this Agreement to CLIENT.  Upon termination of the Agreement for any" +
          " reason, each party agrees to return or destroy all Confidential Information of the other party in its possession." +
          "  If requested in writing by a party, the other party agrees to certify in a signed writing that all the" +
          " Confidential Information of the requesting party has been returned or destroyed."
        ]
      },
      {
        label: "Survivability",
        paragraphs: [
          "The following Sections shall survive the expiration or termination of this Agreement:" +
          "  Section 3 (CLIENT Responsibilities), Section 4 (Pittbos LLC Responsibilities), Section 8 " +
          "(Confidential Information), Section 9 (Disclaimer of Warranties), Section 10 (Indemnity; Limitation of Liability)," +
          " and Section 12 (Miscellaneous Provisions)."
        ]
      },
    ]
  },
  {
    title: "12. Miscellaneous Provisions",
    nav: "Miscellaneous Provisions",
    id: "12",
    description: [
      {
        label: "Successors and Assigns",
        paragraphs: [
          "This Agreement shall be binding upon and shall inure to the benefit of the permitted successors" +
          " and assigns of each party hereto.  Either party may assign, subcontract, delegate or otherwise convey" +
          " this Agreement, or any of its rights and obligations hereunder, to any entity with the prior written" +
          " notification of the other, at least thirty (30) days in advance, so long as the assignee has equal or better" +
          " financial solvency than the assigning party and agrees in writing to be bound by the terms and conditions of" +
          " this Agreement. If any such assignment, subcontracting, delegation or conveyance is attempted without prior" +
          " notification, or the intended assignee has not agreed in writing to be bound by the terms of this Agreement," +
          " such assignment shall be void.  Either party may, from time to time, by written notice to the other, delegate " +
          "one or more of its duties hereunder to one of its Affiliates; provided that the assigning party remains liable" +
          " and responsible for its obligations under this Agreement."
        ]
      },
      {
        label: "Force Majeure",
        paragraphs: [
          "Neither party shall be liable for delays or failure in its performance hereunder to the extent that such delay" +
          " or failure is caused by any act of God, war, natural disaster, fire, third-party criminal act, quarantine" +
          " restriction or act of government, or any other event beyond the reasonable control of that party and that" +
          " could not have been avoided with due diligence (an “Excusable Delay”).  In the event an Excusable Delay" +
          " continues for 30 days or longer, the other party shall have the right, at its option, to immediately terminate" +
          " this Agreement by giving the party whose performance has failed or been delayed by the Excusable Delay written" +
          " notice of such election to terminate, giving the party whose performance has failed or been delayed by the" +
          " Excusable Delay written notice of such election to terminate."
        ]
      },
      {
        label: "Entire Agreement; Amendment; Waiver",
        paragraphs: [
          "This Agreement and all Exhibits hereto constitute the entire agreement between the parties with respect" +
          " to the subject matter and supersede any prior or contemporaneous agreement or understanding, whether written" +
          " or oral, if any, between the parties with respect to such subject matter.  This Agreement shall be construed" +
          " as if both parties had equal say in its drafting, and thus shall not be construed against the drafter." +
          "  This Agreement may be modified only by a further written agreement signed by all of the parties hereto." +
          "  No waiver of breach of any provision of this Agreement by either party shall constitute a waiver of any" +
          " subsequent breach of the same or any other provision, and no waiver shall be effective unless made in writing " +
          "and signed by an officer of the other party.\\nThis Agreement and the rights and obligations of the parties under" +
          " this Agreement shall be governed by, and construed and interpreted in accordance with, the laws of the" +
          " Commonwealth of Massachusetts, excluding its rules of conflicts of law.",
          "Governing Law; Interpretation"
        ]
      },
      {
        label: "Severability",
        paragraphs: [
          "If any one or more of the provisions of this Agreement, or the application thereof in any circumstance," +
          " is held to be invalid, illegal or unenforceable in any respect for any reason, the validity, legality" +
          " and enforceability of any such provision in every other respect and the remaining provisions of this Agreement" +
          " shall be unimpaired and this Agreement shall continue in full force and effect, unless the provisions held" +
          " invalid, illegal or unenforceable shall substantially impair the benefits of the remaining provisions hereof."
        ]
      },
      {
        label: "Relationship of the Parties",
        paragraphs: [
          "Nothing in this Agreement is intended or shall be construed to create or establish any agency," +
          " partnership or joint venture relationship between the parties.  The parties expressly disclaim such relationship," +
          " agree that they are acting solely as independent contractors hereunder and agree that the parties have no " +
          "fiduciary duty to one another or any other special or implied duties that are not expressly stated herein."
        ]
      },
      {
        label: "Counterparts",
        paragraphs: [
          "This Agreement may be executed in any number of counterparts, each of which shall be an original," +
          " and such counterparts together shall constitute one and the same instrument. Execution may be affected" +
          " by delivery of facsimiles of signature pages (and the parties shall follow such delivery by prompt delivery " +
          "of originals of such pages)."
        ]
      },
      {
        label: "Compliance with Law",
        paragraphs: [
          "The parties mutually covenant that each will comply with all applicable federal, state and local" +
          " laws and regulations applicable to its performance under this Agreement.  Both parties further warrant" +
          " that each is qualified to do business in the geographic in which it will perform its obligation under this" +
          " Agreement and will obtain all necessary licenses, permits, and satisfy any other legal, regulator," +
          " and administrative requirements necessary to its performance hereunder"
        ]
      },
    ]
  },
  {
    title: "3. Application License & Implementation Fees",
    nav: "License & Fees",
    id: "13",
    description: [
      {
        label: "Products and Services Include:",
        paragraphs: [
          ""
        ]
      },
      {
        label: "System Configuration",
        paragraphs: [
          "One time fee  - $1,500 – One-time fee"
        ]
      },
      {
        label: "Pittbos Full User Licenses",
        paragraphs: [
          "Monthly Fee - Includes all Pittbos modules - $200 Per Month Per User"
        ]
      },
      {
        label: "User Training",
        paragraphs: [
          "Included in System Configuration"
        ]
      },
      {
        label: "Annual Maintenance & Support",
        paragraphs: [
          "Included in User License Fee"
        ]
      },
    ]
  },
  {
    title: "Appendix A",
    nav: "Appendix A",
    id: "14",
    description: [
      {
        label: "Pittbos LLC Service Level Agreement",
        paragraphs: [
          ""
        ]
      },
      {
        label: "Introduction",
        paragraphs: [
          "These Service Level Terms and Conditions describe the level of application availability Pittbos agrees to provide " +
          "Services are provided to enable Client, affiliates to meet its business objectives, as they relate to the " +
          "Application."
        ]
      },
      {
        label: "Service",
        paragraphs: [
          "The Services described will be made available to all designated users during the hours of operation." +
          " (See Section 3. For definition of available and Availability). Designated users will be identified" +
          " by Client and listed as an attachment to these Terms and Conditions.",
          "Hours of Operation.  The service levels are guaranteed during business hours," +
          " from 7 AM until 10 PM EST, Monday through Friday, excluding holidays, as defined by Client, Affiliate."
        ]
      },
      {
        label: "Scheduled Maintenance",
        paragraphs: [
          "To the greatest extent possible, all maintenance for the Pittbos LLC application will be performed outside" +
          " of the Hours of Operation.  In any case, all scheduled maintenance will be planned to allow for ample" +
          " notification of users, at least 3 days in advance."
        ]
      },
      {
        label: "Upgrades",
        paragraphs: [
          "Upgrades.  From time to time, Pittbos will issue upgrades to its application." +
          " These system wide upgrades are intended to enhance features, improve performance or address other client" +
          " requirements. Pittbos LLC shall provide system wide upgrade, updates and enhancements to the Client at no" +
          " additional cost."
        ]
      },
      {
        label: "Service-Level Requirements",
        paragraphs: [
          ""
        ]
      },
      {
        label: "Availability",
        paragraphs: [
          "Availability is defined as the ability to access primary functions as described in the Pittbos LLC documentation.  Primary functions include creating, adding and modifying grievance, personnel or arbitration documents."
        ]
      },
      {
        label: "Client Availability",
        paragraphs: [
          "Defined as the ability to access primary functions at the Client.  The Client will be available 99%," +
          " measured monthly.",
          "Server Availability.  Defined as the ability to access primary functions at the server." +
          " This includes receiving transactions from Client machines, accessing the database, and returning results" +
          " to Clients."
        ]
      },
      {
        label: "Response Time",
        paragraphs: [
          "Defined as the capability of the Hosting Services to respond to a HTTP request," +
          " measured as the amount of time that elapses from the moment the Pittbos LLC system " +
          "receives the HTTP request (at Pittbos LLC web server level) until the result is available" +
          " at the web server level of Pittbos LLC computing infrastructure.  The quarterly (calendar quarter)" +
          " average Response Time for server response to all access to the Pittbos LLC and the functionalities" +
          " offered thereon, except any database searches, reporting requests, or data integration processes," +
          " shall not exceed two (2) seconds.  Pittbos LLC will investigate any suspected Response Time problem" +
          " reported by Customer and if Pittbos LLC Response Time result is greater than the metric guaranteed in" +
          " this Section, Pittbos LLC will take all commercially reasonable efforts to correct the problem. Should" +
          " Pittbos LLC fail to meet an average quarterly Response Time of 5 seconds, Pittbos LLC will apply a credit" +
          " to Customer's account for such period in an amount equal to three (3) day’s usages fees."
        ]
      },
      {
        label: "Service Levels",
        paragraphs: [
          "Each service-level requirement will be measured and reported as an overall percentage," +
          " calculated by dividing the time that a particular requirement level is met or exceeded by" +
          " the total time that service must provided during hours of operation.  The resulting quotient " +
          "is then multiplied by 100.  [Total hours of operation (all clients) – accumulated downtime]" +
          " / [Total hours of operation (all clients) x 100]"
        ]
      },
      {
        label: "Exclusions",
        paragraphs: [
          "Other Networks or Providers.  Excluded from the measure of Availability is downtime caused by any of the" +
          " following: Failures in the internal networks of Client, Affiliate " +
          "Failures in Client, Affiliate’s ISP or telecom provider " +
          "Failures in desktop hardware or systems " +
          "Scheduled maintenance or upgrades " +
          "Failures in Microsoft Outlook, Word and Excel, or Word Templates"
        ]
      },
      {
        label: "Remedies",
        paragraphs: [
          ""
        ]
      },
      {
        label: "Discounts",
        paragraphs: [
          "If Availability for any month does not meet the stated service-level requirements," +
          " Client, Affiliate is entitled to a 10% reduction in the previously paid monthly fee." +
          " Discounts are applied only to those invoice items that are affected by the service deviation." +
          " For example, a discount due to degraded Server Availability would not apply to professional services for" +
          " customization of application software.\\nTermination.  If the service-level requirements are not met for" +
          " 3 months consecutively, Client, Affiliate may at its option terminate the Agreement for cause."
        ]
      },
      {
        label: "Support",
        paragraphs: [
          ""
        ]
      },
      {
        label: "Support",
        paragraphs: [
          "Technical “help desk” support is available to all designated Client, Affiliate users during the hours " +
          "8AM through 8PM EST, Monday through Friday, excluding holidays designated by Client." +
          " All designated users must receive training for the Pittbos LLC application before they are eligible for support." +
          "  It is the obligation of Client to ensure that all designated users have received adequate training. " +
          "Limitation.  Technical support is limited to the functions and features of the Pittbos LLC program." +
          " Unless otherwise specifically contracted, support does not extend to the Microsoft Office applications " +
          "(Outlook, Word, Excel, etc.) or any other desktop software, including the operating system."
        ]
      },
      {
        label: "Submitting Requests",
        hasEmail: true,
        paragraphs: [
          "The following telephone number or email address may be used to submit request. 1-617-322-5159 or "
        ]
      },
    ]
  },
]