import React from "react";
  import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
  import { Helmet,HelmetProvider} from 'react-helmet-async';

import {LegalPage, MainPage, PricingPage, TeamPage} from "./pages";
import { Footer, Header } from "./components/common";
import { useScrollToTop } from "./hooks";

import "./index.css";

export const App = () => {
  const helmetContext = {};
  useScrollToTop();
  

  return (

    <React.Fragment>
      <div>
        <HelmetProvider context={helmetContext}>
      
     

      <Header />
 
   
      <Routes>

        <Route path="/" element={<MainPage />}  />
        <Route path="team/:id" element={<TeamPage />} />
        <Route path="legal/:page" element={<LegalPage />} />
        <Route path="pricing" element={<PricingPage />} />        
        
        
      </Routes>
      
      <Footer />
      </HelmetProvider> 
      </div>
    </React.Fragment>
    
  );
};
