import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";

import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./fonts/Soleto-Black.woff";
import "./fonts/Soleto-Black.woff2";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
