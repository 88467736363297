import React from 'react';
import { customerFollowUpItems } from '../../data/customerFollowUpItems';
import styles from './CustomerFollowUp.module.css';
import clsx from 'classnames';

const titleStyles = {
  'base': styles.titleBase,
  'red': styles.titleRed,
}

export function CustomerFollowUpItem({ icon: Icon, title, titleColor = 'base', description, className, children }) {
  return (
    <div className={clsx(styles.item, className)}>
      <div className={styles.itemHeader}>
        <Icon/>
        <h4 className={titleStyles[titleColor]}>{title}</h4>
      </div>
      <p className={styles.itemDescription}>{description}</p>
      {children}
    </div>
  )
}

export function CustomerFollowUpItems() {
  return (
    <React.Fragment>
      {customerFollowUpItems.map(
        ({ icon: Icon, title, description }) => (
          <CustomerFollowUpItem icon={Icon} title={title} description={description}/>
        )
      )}
    </React.Fragment>
  )
}