import React from "react";
import clsx from "classnames";

import styles from "./Input.module.css";

export const Input = ({ inputClasses = "", ...rest }) => {
  const inputStyles = clsx(inputClasses, styles.input);

  return <input className={inputStyles} {...rest} />;
};
