import clsx from "classnames";
import { Link } from "react-router-dom";

import { legalData } from "../../../data";
import style from "./Content.module.css";

export const NavContent = ({page}) => {
  return (
    <>
      <div className={style.navHeaderBlock}>
        <h3 className={clsx('text-blue', style.header)}>Contents</h3>
      </div>
      <div className={style.links}>
        {
          legalData.map(item => {
            return (
              <Link
                className={clsx(style.link,
                  {[style.active]: item.id === page},
                  {"text-blue": item.id !== page}
                )}
                key={item.id} to={`/legal/${item.id}`}>
                  {item.nav}
              </Link>
            )
          })
        }
      </div>
    </>
  )
}