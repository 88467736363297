import { useEffect, useState } from 'react';
    
export function useFetch(url) {
    const [data, setData] = useState([]);
    useEffect(() => {
        async function loadData() {
            const response = await fetch(url);
            if(!response.ok) {
                // oups! something went wrong
                
                return;
            }
    
            const posts = await response.json();            
            setData(posts.posts);     
        }
    
        loadData();
    }, [url]);
    return {data}
}