import React from 'react';
import { backlogAndInvoicingCards } from '../../data/backlogAndInvoicingCards';

import styles from './BacklogAndInvoicing.module.css';
import { Helmet } from 'react-helmet-async';

export function BacklogAndInvoicingCards() {
  return (
    <React.Fragment>
      <div>
        <Helmet>
          <title>Pittbos - Contractor Estimating Platform - Backlog & Invoicing</title>
          <meta
            name="description"
            content="Pittbos - Contractor Estimating Platform - Backlog & Invoicing"
          />
        </Helmet>
      {backlogAndInvoicingCards.map(
        ({ image, title }, index) => (
          <div className={styles.card}>
            <div className={styles.cardImageContainer}>
              <img src={image} alt=""/>
            </div>
            <div className={styles.cardInfo}>
              <span className={styles.cardNumber}>{index + 1}</span>
              <div>{title}</div>
            </div>
          </div>
        ))
      }
      </div>
    </React.Fragment>
  )
}