import { ReactComponent as LeadManagementIcon } from "../icons/lead_management_icon.svg";
import { ReactComponent as ProposalsIcon } from "../icons/proposals_icon.svg";
import { ReactComponent as EstimatesIcon } from "../icons/estimates_icon.svg";
import { ReactComponent as DocumentStorageIcon } from "../icons/document_storage_icon.svg";
import { ReactComponent as CloudMobilityIcon } from "../icons/cloud_mobility_icon.svg";
import { ReactComponent as TakeOffIcon } from "../icons/take_off_icon.svg";
import { ReactComponent as CRMIcon } from "../icons/crm_icon.svg";
import { ReactComponent as QuickbooksIcon } from "../icons/quickbooks_icon.svg";

export const customerFollowUpItems = [
  {
    icon: LeadManagementIcon,
    title: 'Lead Management',
    description:
      'Pittbos systems allow your company to acquire and manage potential new business, streamlining the whole process of securing a contract.'
  },
  {
    icon: ProposalsIcon,
    title: 'Proposals',
    description:
     'Send accurate, comprehensive proposals to clients for their digital review. Proposals can also be accepted, signed, and stored directly through the platform.'
  },
  {
    icon: EstimatesIcon,
    title: 'Estimates',
    description:
    'Never let an incorrect Excel formula cost you money again. Create accurate, timely estimates using your preloaded expenses for products, labor, travel, etc.'
  },
  {
    icon: DocumentStorageIcon,
    title: 'Document Storage',
    description:
    'Eliminate all that paperwork. Store details for every job within the customer portal, including job site photos, aerial worksite measurements, digitally signed contracts, and more.'
  },
  {
    icon: CloudMobilityIcon,
    title: 'Cloud Mobility',
    description:
    'Our entire platform is backed up in the cloud, providing you with the peace of mind that you’ll never lose a quote or important worksite details. Pittbos also has full mobile capabilities, allowing you to manage your business from anywhere.'
  },
  {
    icon: TakeOffIcon,
    title: 'Take-off',
    description:
    'Measure a work site from the comfort of your office. Our unique take-off feature provides you with an aerial view of any potential worksite, allowing you to take complete measurements'
  },
  {
    icon: CRMIcon,
    title: 'Customer Relationship Management (CRM)',
    description:
    'Pittbos technology helps your company manage and analyze customer interactions throughout the entire customer lifecycle, improving customer relationships and assisting in customer retention and sales growth.'
  },
  {
    icon: QuickbooksIcon,
    title: 'Quickbooks integration',
    description:
      'Once your jobs are complete and you’re preparing your invoices, you can seamlessly push that information directly into your Quickbooks account through our integrated system for fast and easy bookkeeping.'
  }
]