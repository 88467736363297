import mark_peach_photo from "../images/mark_peach.png";
import devyn_coviello_photo from "../images/devyn_coviello.png";
import jeff_hardy_photo from "../images/jeff_hardy.png";

export const team = [
  {
    photo: mark_peach_photo,
    redirect: "mark-peach",
    title: "Mark Peach",
    description: [
      "Over the past 20 years, Mark has held a variety of positions within the construction materials industry.  Starting in the early 2000's with Aggregate Industries, as the Liquid Asphalt Coordinator, helping buy and distribute liquid asphalt into the New England market. Having an interest in contracting, Mark moved into a position of Asphalt Contracting Salesman and was promoted to Contracting Sales Manager in his late twenties.",
      "  Looking for more growth opportunities, in 2014 Mark was hired by Benevento Companies, as the General Manager of the asphalt division.  Providing value and growth in the asphalt division, Mark was given the opportunity to manage the concrete division as well.  Lastly, after building strong teams and culture, Mark was promoted to Vice President of Benevento Companies, only reporting to the owner.",
      "  Mark graduated with a business management degree from Curry College in 2000, where he is still an active alumni.  He spends his free time enjoying fishing, boating, and golfing with his family on Lake Ossipee in New Hampshire.",
    ],
    linkedin: "https://www.linkedin.com/in/mark-peach-4a3b3230",
    mail: "mp@pittbos.com",
  },
  {
    photo: jeff_hardy_photo,
    redirect: "jeff-hardy",
    title: "Jeff Hardy",
    description: [
      "Jeff's career spans over two decades, engineering software solutions for various regulated industries.  Most recently, Jeff spent the last 7 years in the fintech industry empowering individuals under public state and federal waiver programs to live independently.",
      "  Focused on architecting solutions and managing large development teams, Jeff gained the expertise to build and scale teams and solutions within any industry.",
      "  Today, Jeff is bringing his experience to Pittbos to help build the next generation of software solutions for the asphalt industry and beyond.",
      "  Jeff is a graduate of the University of Massachusetts at Lowell with a degree in Computer Science, residing in the Boston area with his wife and three children.", 
    ],
    linkedin: "https://www.linkedin.com/in/jeffrey-m-hardy1/",
    mail: "jeff.hardy@pittbos.com",
  },
  {
    photo: devyn_coviello_photo,
    redirect: "devyn-coviello",
    title: "Devyn Coviello",
    description: [
      "Pittbos would like to welcome Devyn Coviello to the team as our 'Client Success Manager'. Devyn has grown up in the paving industry with her grandfather and father each owning a paving company.",
      "  After graduating from Southern New Hampshire University, she started her career in recruiting, brand promotion and customer relations to build the experience and repertoire needed to be successful within the asphalt industry. She has always been focused on proactive problem solving and finding solutions for her clients. Her center is developing customer relationships that promote retention and loyalty!",
      "  She has now stepped back into the industry that she loves and grew up in, to ensure Pittbos's customers are maximizing the application and being successful while doing so.",
    ],
    linkedin: "https://www.linkedin.com/in/devyn-coviello-7672231a3",
    mail: "devyn@pittbos.com",
  },
];
 