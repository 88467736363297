import clsx from "classnames";

import { legalData } from "../../../data";
import style from "./Content.module.css";
import { Helmet } from "react-helmet-async";

export const MainContent = ({page}) => {
  return (
    <div>
      <Helmet>
        <title>Pittbos - Contractor Estimating Platform - Legal</title>
        <meta
          name="description"
          content="Pittbos - Contractor Estimating Platform - Legal"
        />
      </Helmet>
    <>
      <div className={style.headerBlock}>
        <h2 className={clsx('text-blue', style.mainHeader)}>Pittbos terms & policies</h2>
        <p className={style.date}>October 2022</p>
      </div>
      <div>
        {
          legalData.map(lg => {
            if (lg.id === page) {
              return (
                <div key={lg.id}>
                  <h4 className={style.title}>{lg.title}</h4>
                  {
                    lg.description.map((desc, index) => {
                      const hasEmail = desc?.hasEmail;

                      return (
                        <div key={index}>
                          {
                            desc.label &&
                            <p className={style.label}>{desc.label}</p>
                          }
                          <div>{desc.paragraphs.map((par, idx) => {
                              return (
                                <p className={clsx("text-blue", style.paragraphs)} key={idx}>
                                  {
                                    hasEmail
                                      ? <span>
                                          {par}
                                          <a className={clsx(style.email, 'text-blue')} href='mailto:support@pittbos.com'>
                                            support@pittbos.com
                                          </a>
                                        </span>
                                      : par
                                  }
                                </p>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          })
        }
      </div>
    </>
    </div>
  )
}