import React from "react";
import clsx from "classnames";

import styles from "./Container.module.css";

export const Container = (
  {
    children,
    backgroundWhite = false,
    backgroundDarkBlue = false,
    backgroundLightBlue = false,
    backgroundLightGray = false,
    large = false,
    small = false,
    title = "",
    wrapperClasses = "",
    backgroundClasses = "",
    othetStyles = {},
    id = ""
  },
) => {
  const backgroundColorStyle = clsx(
    { "background-dark-blue": backgroundDarkBlue },
    { "background-light-blue": backgroundLightBlue },
    { "background-light-gray": backgroundLightGray },
    { "background-white": backgroundWhite },
    backgroundClasses
  );

  const wrapperStyles = clsx(
    { [styles.large]: large },
    { [styles.small]: small },
    { [styles.withTitle]: title },
    styles.wrapper,
    wrapperClasses
  );
  const titleStyles = clsx("heading", styles.title);

  return (
    <div className={backgroundColorStyle} id={id} style={othetStyles}>
      <div className={wrapperStyles}>
        {title && <h2 className={titleStyles}>{title}</h2>}
        {children}
      </div>
    </div>
  );
};
