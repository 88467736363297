import { useFetch } from "../../hooks";
import React from 'react';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container } from "../common";
import styles from '../../components/Blog/blog.module.css';
import clsx from 'classnames';

import SEO from "../SEO/SEO";

export function Posts() {
  const titleStyles = clsx("title text-white text-center", styles.title);
  //const posts = useFetch('https://ma.tt/wp-json/wp/v2/posts');
  const posts = useFetch('https://public-api.wordpress.com/rest/v1.2/sites/218065156/posts');

  return (

    <React.Fragment>
      <div>
     <SEO
        title="Pittbos - Contractor Estimating Platform - Blog"
        description="Pittbos - Contractor Estimating Platform - Blog"
        name="Pittbos"
        type="website"
      />
      <div  className={styles.containerBackground}>
        <Container align="center" id="blog" wrapperClasses={styles.wrapper}>
          <p className={titleStyles}>Latest Pittbos and industry news!</p>
          <Grid container align="left-justified" spacing={3} xs={9}  >
            {posts.data.map((post, index) => (                
              <Grid item xs={12} key={index} >
                <Card square={false} rounded elevation1>
                  <CardContent>
                    <Typography
                      color="textPrimary"
                      variant="h5"                 
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <Typography
                        color="textSecondary"                                       
                        variant="subtitle2"
                        component="i"
                        dangerouslySetInnerHTML={{ __html: ` - ${post.date} by ${post.author.name}` }}
                      />                  
                    <Typography
                      variant="body2"
                      component="div"
                      weight="regular"
                      align="justify"
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      </div>
    </React.Fragment>
  
  );
}

  

