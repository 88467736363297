import { Pricing } from "../components";
import SEO from "../components/SEO/SEO";
import { useDocumentTitle } from "../hooks";


export const PricingPage = () => {

  useDocumentTitle("Pittbos - Contractor Estimating Platform - Pricing");

  return (
<div>

<SEO
  title="Pittbos - Contractor Estimating Platform - Pricing"
  description="Pittbos - Contractor Estimating Platform - Pricing"  
  name="Pittbos"
  type="website"
/>
    
  <Pricing />

  </div>

  );
};
