import backlogAndInvoicesFirstCardImage from '../images/backlog_and_invoices_card_1.png';
import backlogAndInvoicesSecondCardImage from '../images/backlog_and_invoices_card_2.png';
import backlogAndInvoicesThirdCardImage from '../images/backlog_and_invoices_card_3.png';

export const backlogAndInvoicingCards = [
  {
    image: backlogAndInvoicesFirstCardImage,
    title: 'Complete the work',
  },
  {
    image: backlogAndInvoicesSecondCardImage,
    title: 'Generate invoices',
  },
  {
    image: backlogAndInvoicesThirdCardImage,
    title: 'Get paid faster',
  },
];