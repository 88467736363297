import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import clsx from "classnames";

import { team } from "../../data";

import styles from "./MeetTheTeam.module.css";
import SEO from "../SEO/SEO";

export const MeetTheTeam = () => {
  const params = useParams();

  const currentPerson = useMemo(
    () => team.find((el) => el.redirect === params?.id),
    [params?.id]
  );

  const titleStyles = clsx("text-red", "heading", styles.title);
  const descriptionStyles = clsx("description", styles.description);

  return (
    <div>
    <SEO
      title={`Pittbos - Contractor Estimating Platform - Team ${params.id
        .split("-")
        .map((item) => item.replace(/./, (c) => c.toUpperCase()))
        .join(" ")}`}
      description={`Pittbos - Contractor Estimating Platform - Team ${params.id
        .split("-")
        .map((item) => item.replace(/./, (c) => c.toUpperCase()))
        .join(" ")}`}
      name="Pittbos"
      type="website"
    />
    

    <div className={styles.wrapper}>
 
      <div className={styles.itemWrapper}>
        <h3 className={titleStyles}>{currentPerson.title}</h3>
        {currentPerson.description.map((descr) => (
          <p key={descr} className={descriptionStyles}>
            {descr}
          </p>
        ))}
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={currentPerson.photo}
          alt={currentPerson.title}
          className={styles.image}
        />
      </div>
    </div>
    </div>

  );
};
