import React from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import styles from './WatchDemoVideo.module.css';

const modal = document.getElementById("modal-root");

export function WatchDemoVideo({ setIsVisible }) {

  const close = () => {
    setIsVisible(false);
  }

  return createPortal(
    <div>
      <div onClick={close} className={styles.overlay}></div>
      <div onClick={close} className={styles.close}>
        <CloseIcon width={20} height={20}/>
      </div>
      <div className={styles.modal}>
        <div className={styles.videoWrapper}>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/880934255?h=39159f1156"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    </div>
    ,
    modal)
}

