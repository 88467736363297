import React, { useRef } from "react";
import { createPortal } from "react-dom";
import clsx from "classnames";

import { ReactComponent as CloseIcon } from "../../../icons/close.svg";

import styles from "./Modal.module.css";

const modal = document.getElementById("modal-root");

export const Modal = ({ setIsVisible, title, description, children }) => {
  const modalRef = useRef();

  const close = () => {
    setIsVisible(false);
  };

  const titleStyles = clsx("text-blue", styles.title);
  const descriptionStyles = clsx("description", styles.description);

  return createPortal(
    <React.Fragment>
      <div className={styles.overlay} onClick={close}></div>
      <div className={styles.wrapper} ref={modalRef}>
        <div className={styles.close} onClick={close}>
          <CloseIcon />
        </div>
        <div>
          {title && <h2 className={titleStyles}>{title}</h2>}
          {description && <p className={descriptionStyles}>{description}</p>}
        </div>
        {children}
      </div>
    </React.Fragment>,
    modal
  );
};
