import React from "react";

import { Container } from "../common";
import { TeamItems } from "./TeamItems";

export const Team = ({ title, small = false }) => {
  return (
    <Container backgroundLightGray small={small} title={title}>
      <TeamItems />
    </Container>
  );
};
