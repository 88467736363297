import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "classnames";

import { team } from "../../data";

import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as LinkedinIcon } from "../../icons/linkedin.svg";

import styles from "./TeamItems.module.css";

export const TeamItems = () => {
  const navigate = useNavigate();
  const params = useParams();

  const itemWrapperStyles = clsx("background-white", styles.itemWrapper);
  const titleStyles = clsx("mt-30", styles.title);
  const descriptionStyles = clsx("description", styles.desc);

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        {team
          .filter((t) => t.redirect !== params?.id)
          .map(
            (
              { description, linkedin, mail, photo, redirect, title },
              index
            ) => {
              if (index >= 3) return null

              return (
                <div
                  className={itemWrapperStyles}
                  key={`${redirect}-${index}`}
                  onClick={() => navigate(`/team/${redirect}`)}
                >
                  <div>
                    <img className={styles.image} src={photo} alt={title} />
                  </div>
                  <div className={styles.titleWrapper}>
                    <p className={titleStyles}>{title}</p>
                    <p className={descriptionStyles}>{description}</p>
                    <div className={styles.linksWrapper}>
                      <LinkedinIcon
                        className={styles.link}
                        onClick={() => window.open(linkedin, "_blank")}
                      />
                      <MailIcon
                        className={styles.link}
                        onClick={() => window.open(`mailto:${mail}`, "_blank")}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>
    </React.Fragment>
  );
};
