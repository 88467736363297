import style from "./SubNavContent.module.css";

export const SubNavContentContainer = ({nav, content}) => {
  return(
    <div className={style.container}>
      <div className={style.nav}>
        {nav}
      </div>
      <div className={style.content}>
        {content}
      </div>
    </div>
  )
}